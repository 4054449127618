@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800,900");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.textPink {
  color: #d62b70 !important;
  background: -webkit-linear-gradient(-70deg, #772bff 0%, #d62b70 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-weight: 500;
  text-transform: capitalize;
}

.pink {
  color: #d62b70 !important;
}
.grayText {
  color: yellow !important;
}
.grayText:hover {
  color: black !important;
}
.location {
  background: -webkit-linear-gradient(-70deg, #00dd71 0%, #0076ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-weight: 500;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* sundram css */
.brdr-btm {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.pd-1rem {
  padding: 1rem;
  margin-bottom: 0 !important;
}

.bg-gradient {
  /* background: -webkit-linear-gradient(
    -70deg,
    #986aee 0%,

    #f0575a 100%) */

  background: -webkit-linear-gradient(-70deg, #986aee 0%, #f0575a 80%, #ffe57f 100%);
}

.signIncont {
  box-shadow: 0 0 0 1px rgb(53 72 91 / 14%), 0 2px 2px rgb(0 0 0 / 3%), 0 4px 4px rgb(0 0 0 / 4%), 0 10px 8px rgb(0 0 0 / 5%),
    0 15px 15px rgb(0 0 0 / 6%), 0 30px 30px rgb(0 0 0 / 7%), 0 70px 65px rgb(0 0 0 / 9%) !important;
  border-radius: 15px;
}

.ccc {
  background: #ff188c !important;
  border: none !important;
  text-transform: uppercase !important;
  font-weight: bolder !important;
}

.ccc:hover {
  background: #d62b70 !important;
}

.pinkBtn {
  background: #ff188c !important;
  border: none !important;
}

.pinkBtn:hover {
  background: #d62b70 !important;
}

.video-section .item {
  opacity: 0.4;
  transition: 0.4s ease all;
  margin: 0 20px;
  transform: scale(0.8);
}

@media (max-width: 1000px) {
  .video-section .item {
    margin: 0;
    transform: scale(0.9);
  }
}

.video-section .active .item {
  opacity: 1;
  transform: scale(1);
}

body {
  margin: 80px 0 0 0;
}

.video-section .owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.video-section video {
  max-width: 100%;
  height: auto;
}

/* for video carousel  */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
section {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 10px;
  position: relative;
  overflow: hidden;
}

.bx bx-x close {
  top: 0% !important;
  right: 0% !important ;
}
section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.4s ease;
}
section.active::before {
  opacity: 1;
}
.container {
  max-width: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: transparent;
  padding: 5px 10px;
  position: relative;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5); */
}
section.active .container {
  visibility: hidden;
}
.container .main-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  visibility: visible;
  transition: all 0.4s ease;
}
section.active .container .main-video {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  pointer-events: auto;
  height: -webkit-fill-available;
}

.container .main-video video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  outline: none;
}
.container .main-video .close {
  position: absolute;
  top: 0%;
  right: 0%;
  font-size: 35px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  pointer-events: auto;
  opacity: 0.6;
}
.container .main-video .close:hover {
  opacity: 1;
}
.container .videos {
  position: relative;
  height: 100px;
  width: calc(100% / 6 - 5px);
  margin: 5px 0;
  cursor: pointer;
}
.container .videos::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.container .videos video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  outline: none;
  pointer-events: none;
}
.container .videos i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #fff;
  pointer-events: none;
}
@media (max-width: 750px) {
  .container .main-video {
    position: fixed;
    width: 83%;
  }
  .container .videos {
    width: 100%;
    height: 320px;
  }
}
@media (max-width: 600px) {
  .container .videos {
    width: 100%;
    height: 250px;
  }
}

.w-80 {
  width: 80% !important;
  margin: 0 auto;
}
.inline {
  display: inline-block;
}
.inline h1 {
  margin-bottom: 0.1rem;
  background: -webkit-linear-gradient(133deg, #9867f0 0%, #ff5fa0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-weight: bolder;
  font-size: 2.4rem;
}

.quill {
  width: 100%;
}

.lightVdo {
  position: relative;
  height: 100px;
  width: calc(100% / 6 - 10px);
  margin: 5px;
  cursor: zoom-in;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.viewGlry {
  position: absolute;
  top: 65%;
  color: transparent;
  right: 1%;
  transform: translate(-50%, -50%);
  cursor: zoom-in;

  transition: 0.2s;
}
.viewGlry span {
  color: transparent;
}

.glry {
  position: relative;
  display: contents;
  transition: 0.2s;
}
.glry:hover .lightVdo {
  filter: brightness(0.7);

  transition: 0.2s;
}

.glry:hover .viewGlry {
  color: white;
  transition: 0.2s;
  top: 58%;
}

@media (max-width: 963px) {
  .viewGlry {
    display: none;
  }

  .lightVdo {
    position: relative;
    height: 100px;
    width: calc(100% / 3 - 10px);
  }
}

@media (max-width: 1077px) {
  .viewGlry {
    right: -2.5%;
  }
}

@media (max-width: 354px) {
  .lightVdo {
    position: relative;
    height: 100px;
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .viewGlry {
    right: -1%;
  }
}

.recharts-wrapper {
  margin: 0 auto;
}

.totalUserBar {
  background-image: linear-gradient(red, yellow);
  fill: #d62b70;
}

table {
  width: 100%;
}
